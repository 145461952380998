<template>
  <div>
 
  <InventarioActual :id_puesto="store.state.puestoSelect.id_puesto" />
</div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'
import InventarioActual from '@/views/sistema/inventario/reporte/inventario-actual/InventarioActual.vue'
import store from '@/store'
// demos

export default {
  components: { InventarioActual },
  setup() {
    return {store}
  },
}
</script>
